@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/mixins";
@use "../../utilities/functions";

.list-two-line-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-family: fonts.$proxima;

  &__title {
    color: neutral.$neutral_06;
  }

  &__bottom {
    padding-top: spacing.$spacing-xxxs;
    color: neutral.$neutral_04;
  }

  &__bottom-right:not(:empty) {
    margin-left: spacing.$spacing-xxs;
  }
}
