@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/mixins";

.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  &__menu {
    @include mixins.reset-list;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border-bottom: 1px solid neutral.$neutral_03;
  }

  &__content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    overflow: auto;
  }

  &__tab {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}

.tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 40px;

  border: none;
  border-bottom: 2px solid transparent;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: -1px;

  &__action {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    &.is-container {
      padding: 0 spacing.$spacing-m !important;
    }
  }

  &--active {
    border-bottom-color: accent.$accent_06;
  }

  &--disabled#{&}--active {
    border-bottom-color: neutral.$neutral_03;
  }

  .icon-select {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.tab-panel {
  display: none;

  &--active {
    display: block;
  }
}
