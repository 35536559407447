@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/color/theme";
@use "../../utilities/variables/spacing";
@use "../../utilities/mixins";

.scroll-bar {
  /*setting min-height in case the scroll bar is placed in a flexbox*/
  min-height: 0;

  &:after,
  &:before {
    @include mixins.size(100%, 24px);
    position: absolute;
    pointer-events: none;
  }

  @mixin fade($deg: 0, $fade-start-color: neutral.$white_100) {
    background: linear-gradient(
      $deg,
      rgba($fade-start-color, 1),
      rgba($fade-start-color, 0) 72.73%
    );
  }

  &--top-indicator {
    &:before {
      @include fade(180deg);
      content: "";
      top: 0;
      z-index: 1;

      @include mixins.variant-parent(".bg-dark-lighter") {
        @include fade(180deg, theme.$dark-lighter);
      }

      @include mixins.variant-parent(".bg-dark") {
        @include fade(180deg, theme.$dark);
      }

      @include mixins.variant-parent(".bg-accent") {
        @include fade(180deg, theme.$accent);
      }

      @include mixins.variant-parent(".bg-light") {
        @include fade(180deg, theme.$light);
      }

      @include mixins.variant-parent(".bg-light-darker") {
        @include fade(180deg, theme.$light-darker);
      }
    }
  }

  &--bottom-indicator {
    &:after {
      @include fade();
      content: "";
      bottom: 0;

      @include mixins.variant-parent(".bg-dark-lighter") {
        @include fade(0, theme.$dark-lighter);
      }

      @include mixins.variant-parent(".bg-dark") {
        @include fade(0, theme.$dark);
      }

      @include mixins.variant-parent(".bg-accent") {
        @include fade(0, theme.$accent);
      }

      @include mixins.variant-parent(".bg-light") {
        @include fade(0, theme.$light);
      }

      @include mixins.variant-parent(".bg-light-darker") {
        @include fade(0, theme.$light-darker);
      }
    }
  }

  .simplebar-scrollbar {
    &:before {
      background-color: neutral.$neutral_02 !important;
      opacity: 0.5;
      right: spacing.$spacing-xxs;
      border-radius: 2px;
      width: 4px;
      left: unset;

      @include mixins.variant-parent(".bg-dark") {
        background-color: accent.$accent_03 !important;
      }

      @include mixins.variant-parent(".bg-dark-lighter") {
        background-color: accent.$accent_03 !important;
      }

      @include mixins.variant-parent(".bg-accent") {
        background-color: accent.$accent_03 !important;
      }

      @include mixins.variant-parent(".bg-light-darker") {
        background-color: accent.$accent_04 !important;
      }

      @include mixins.variant-parent(".bg-light") {
        background-color: neutral.$neutral_02 !important;
      }
    }

    &.simplebar-visible {
      &:before {
        opacity: 1;
      }
    }
  }
}
