@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/status";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/dimentions";
@use "../../utilities/mixins";

.input-control {
  $base: &;

  width: 100%;
  position: relative;

  &--search {
    &:not(#{$base}--category) {
      .form-control {
        &:focus {
          ~ .icon {
            &.is-left {
              @include mixins.svg-fill(accent.$accent_05);
            }
          }
        }
      }
    }
  }

  &--category {
    .icon {
      &.is-left {
        padding-left: 0;
        @include mixins.svg-fill(neutral.$white_100);

        &:before {
          content: "";
          position: absolute;
          @include mixins.size(32px);
          border-radius: 50%;
          top: spacing.$spacing-xxs;
          left: spacing.$spacing-xxs;
          background-color: accent.$accent_05;
          z-index: -1;
        }
      }
    }
  }

  &--unit {
    width: 78px;

    .form-control {
      min-width: 78px;
      max-width: 647px;
      text-align: right;
      padding-left: spacing.$spacing-xs;
      /*TODO: TTM-672 need to make the right padding dynamic to cater for exact unit width*/
      padding-right: 30px;
    }

    .unit {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 spacing.$spacing-xs;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      line-height: dimentions.$input-height;
      color: neutral.$neutral_06;
    }
  }

  &--dropdown-header {
    input:not(:disabled) {
      cursor: pointer;
    }

    .icon {
      &.is-right {
        pointer-events: none;
      }
    }

    &#{$base}--selected {
      input {
        cursor: initial;
      }

      .icon {
        &.is-right {
          pointer-events: unset;
        }
      }
    }
  }

  .form-control {
    &:not(:disabled) {
      &.is-invalid {
        & ~ .icon {
          &.is-right {
            @include mixins.svg-fill(status.$destructive_04);
          }
        }

        & ~ label {
          color: status.$destructive_04;
        }
      }

      &.is-valid {
        & ~ .icon {
          &.is-right {
            @include mixins.svg-fill(status.$positive_03);
          }
        }

        & ~ label {
          color: status.$positive_03;
        }
      }
    }

    &:disabled {
      $disabled-color: neutral.$neutral_03;

      border-color: $disabled-color !important;
      color: $disabled-color;

      & ~ .icon {
        @include mixins.svg-fill($disabled-color);
      }

      & ~ label {
        color: $disabled-color;
      }

      &::-webkit-input-placeholder {
        color: $disabled-color;
      }

      &::-moz-placeholder {
        color: $disabled-color;
      }

      &:-ms-input-placeholder {
        color: $disabled-color;
      }

      &::-ms-input-placeholder {
        color: $disabled-color;
      }

      &::placeholder {
        color: $disabled-color;
      }

      ~ #{$base}__helper-text {
        color: $disabled-color;
      }
    }
  }

  &__helper-text {
    &:not(.invalid-feedback):not(.valid-feedback) {
      color: neutral.$neutral_04;
    }
    margin-top: spacing.$spacing-xxs;
    padding: 0 spacing.$spacing-m;
  }

  label {
    color: neutral.$neutral_04;
  }

  &.form-floating {
    .form-control {
      &:disabled {
        &::-webkit-input-placeholder {
          color: transparent;
        }
        &::-moz-placeholder {
          color: transparent;
        }
        &:-ms-input-placeholder {
          color: transparent;
        }
        &::-ms-input-placeholder {
          color: transparent;
        }
        &::placeholder {
          color: transparent;
        }
      }
    }
  }
}
