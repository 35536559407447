@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/mixins";

$base: ".icon-select";

#{$base},
a #{$base},
button.is-container #{$base} {
  $icon-margin: spacing.$spacing-xxs;
  $color-accent: accent.$accent_05;
  $color-secondary: neutral.$neutral_04;
  @include mixins.transition(color);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  font-family: fonts.$gilroysemibold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;

  &--24 {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
  }

  &__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    svg path {
      @include mixins.transition(fill);
    }

     + #{$base}__value {
      margin-left: $icon-margin;
    }
  }

  &--horizontal-right-icon {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;

    #{$base}__icon {
      + #{$base}__value {
        margin: {
          right: $icon-margin;
          left: 0;
        }
      }
    }
  }

  &--vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;

    #{$base}__value {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
    }

    #{$base}__icon {
      + #{$base}__value{
        margin: {
          left: 0;
          top: spacing.$spacing-xxxs;
        }
      }
    }
  }

  &--accent {
    @include mixins.text-with-svg-color($color-accent);
  }

  &--accent-icon {
    @include mixins.svg-fill($color-accent);
  }

  &--secondary {
    @include mixins.text-with-svg-color($color-secondary);

    font-family: fonts.$gilroybold;
  }

  &--secondary-icon {
    @include mixins.svg-fill($color-secondary);
  }

  &--hover {
    @include mixins.text-with-svg-color(accent.$accent_06);
  }

  &--disabled {
    @include mixins.button-disabled(neutral.$neutral_03);
  }
}

a,
button.is-container {
  &:hover,
  &:focus {
    .icon-select:not(.icon-select--disabled) {
      @include mixins.text-with-svg-color(accent.$accent_06);
    }
  }
}
