@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/status";
@use "../../utilities/mixins";

.price-indicator {
  $base: &;
  @include mixins.svg-fill(neutral.$neutral_03);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  svg#{$base}--active {
    path {
      fill: status.$alert_02;
    }
  }
}
