@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/effects";
@use "../../utilities/mixins";

.notification {
  $base: &;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: neutral.$white_100;

  &--in-panel {
    width: 320px;
    padding: spacing.$spacing-xs spacing.$spacing-m spacing.$spacing-xs spacing.$spacing-m;
    background-color: neutral.$neutral_01;
    border-radius: 8px;
  }

  &--page-banner {
    width: min(480px, 60vw);
    padding: spacing.$spacing-xs;
    -webkit-box-shadow: effects.$drop-shadow-down-m-dark;
            box-shadow: effects.$drop-shadow-down-m-dark;
    border-radius: 12px;

    #{$base}__message {
      margin: spacing.$spacing-xxs 0 spacing.$spacing-xxs spacing.$spacing-s;
    }
  }

  &--full-page {
    width: min(900px, 90vw);
    padding: spacing.$spacing-xxl;
    -webkit-box-shadow: effects.$drop-shadow-down-m-light;
            box-shadow: effects.$drop-shadow-down-m-light;
    border-radius: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    #{$base}__message {
      text-align: center;
      width: min(440px, 60%);
      margin: spacing.$spacing-s 0 spacing.$spacing-m;
    }

    #{$base}__heading {
      color: neutral.$neutral_04;
      margin-bottom: spacing.$spacing-m;
    }

    #{$base}__close {
      -ms-flex-item-align: center;
          align-self: center;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &__image {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  &__message {
    margin-left: spacing.$spacing-s;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  &__heading {
    margin-bottom: spacing.$spacing-xs;
  }

  &__close {
    -ms-flex-item-align: start;
        align-self: start;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}
