@use "sass:math";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/effects";
@use "../../utilities/mixins";
@use "../../utilities/variables/transitions";

@function _calculateSpace($input, $checkmark, $border: 0) {
  @return math.div($input - $checkmark, 2) - $border;
}

$animation-duration: transitions.$transition-duration-s;

$input-size: 20px;
$input-checkmark-size: 12px;

$radio-size: 22px;
$radio-checkmark-off-size: 14px;
$radio-checkmark-on-size: $input-checkmark-size;
$radio-border: 2px;

$check-size: $input-size;
$check-checkmark-size: $input-checkmark-size;

$switch-height: $input-size;
$switch-width: 36px;
$switch-checkmark-size: 16px;
$switch-checkmark-space: _calculateSpace(
  $switch-height,
  $switch-checkmark-size
);

@mixin hideInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  @include mixins.size(0);
}

/*
Customize the label container to show up as a toggle (checkbox or radio button or switch).
The toggle default type is the checkbox, even is the modifier itself is not supplied.
Element explanation:
.toggle:before = Faux input component to take up the element's space, only visible for the non color changing background (used by checkbox toggle type)
.toggle--checkmark = Same size as .toggle:before to create a changing background color. Done this way to use the input's checked state
.toggle--checkmark:before =  Checkmark's check shading, used to be agnostic of checkmark border
.toggle--checkmark:after =  Checkmark's check indicator
*/
.toggle {
  $base: &;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: $input-size;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  &--with-sub-text {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    #{$base}__sub-text {
      font-size: 0.75rem;
    }
  }

  /* Faux input component - non color changing background */
  &:before {
    content: "";
    position: absolute;
    @include mixins.size($check-size);
    z-index: 0;
    background: neutral.$neutral_01;
    border: 1px solid neutral.$neutral_02;
    border-radius: 4px;
    @include mixins.transition(background, $animation-duration);
  }

  /* Hide the browser's default input to use custom */
  &__input {
    @include hideInput;

    /* Show the checked indicator when checked */
    &:checked + #{$base}__checkmark {
      /* checkmark check indicator */
      &:after {
        opacity: 1;
      }
    }
  }

  /* Custom check mark - check mark including color changing background */
  &__checkmark {
    position: relative;
    z-index: 1;
    @include mixins.size($check-size);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /* check indicator */
    &:after {
      content: "";
      @include mixins.size($check-checkmark-size);
      z-index: 0;
      border-radius: 2px;
      opacity: 0;
      background: accent.$accent_05;
      @include mixins.transition(opacity, $animation-duration);
    }
  }

  &__body {
    &:not(:empty) {
      padding-left: spacing.$spacing-s;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: neutral.$neutral_03;

    &:before {
      background-color: neutral.$neutral_01;
      border-color: neutral.$neutral_01;
    }

    &:before,
    #{$base}__checkmark {
      pointer-events: none;
    }

    #{$base}__input {
      &:checked + #{$base}__checkmark {
        &:after {
          background-color: accent.$accent_04;
        }
      }
    }

    #{$base}__busy {
      svg {
        opacity: 0.5;
      }
    }
  }

  &:not([disabled]) {
    &:hover {
      /* Unchecked hover effect */
      &:before {
        border-color: neutral.$neutral_03;
        background-color: neutral.$neutral_03;
      }

      #{$base}__input {
        /* Checked hover effect */
        &:checked + #{$base}__checkmark {
          &:after {
            background-color: accent.$accent_06;
          }
        }
      }
    }
  }

  /* 
  --- Radio + Switch ---
  Have common background and effects but different input and checkmark size
  */
  &--radio,
  &--switch {
    #{$base}__checkmark {
      background-color: neutral.$neutral_03;
      @include mixins.transition(background-color, $animation-duration);
      -ms-flex-negative: 0;
          flex-shrink: 0;

      /* checkmark indicator elements */
      &:before,
      &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
      }

      /* checkmark indicator shading */
      &:before {
        -webkit-box-shadow: effects.$drop-shadow-down-xs-dark;
                box-shadow: effects.$drop-shadow-down-xs-dark;
        z-index: initial;
      }

      /* checkmark check indicator */
      &:after {
        background-color: neutral.$white_100;
      }
    }

    #{$base}__input {
      /* Checked effect */
      &:checked + #{$base}__checkmark {
        background-color: accent.$accent_05;
      }
    }

    &[disabled] {
      &:before,
      #{$base}__checkmark {
        pointer-events: none;
        background-color: neutral.$neutral_02;
      }

      #{$base}__input {
        /* Disabled checked effects */
        &:checked + #{$base}__checkmark {
          background-color: accent.$accent_03;
          &:before {
            -webkit-box-shadow: 0 0 0;
                    box-shadow: 0 0 0;
          }
          &:after {
            background-color: neutral.$white_100;
          }
        }
      }
    }

    &:not([disabled]) {
      &:hover {
        /* Unchecked hover color */
        #{$base}__checkmark {
          background-color: neutral.$neutral_03;
        }

        #{$base}__input {
          /* Checked hover effect */
          &:checked + #{$base}__checkmark {
            background-color: accent.$accent_06;

            &:after {
              background-color: neutral.$white_100;
            }
          }
        }
      }
    }
  }

  /* --- Radio --- */
  &--radio {
    height: $radio-size;

    &#{$base}--with-sub-text {
      height: auto;
    }

    /* Faux input component */
    &:before {
      @include mixins.size($radio-size);
    }

    /* Checkmark elements */
    &:before,
    #{$base}__checkmark {
      border-radius: 50%;
    }

    /* Custom check mark - check mark including color changing background */
    #{$base}__checkmark {
      @include mixins.size($radio-size);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;

      /* checkmark indicator shading - made visible when checked  */
      &:before {
        @include mixins.size($radio-checkmark-on-size);
        -webkit-box-shadow: 0 0 0;
                box-shadow: 0 0 0;
        @include mixins.transition(box-shadow, $animation-duration);
      }
      /* checkmark check indicator */
      &:after {
        @include mixins.size($radio-checkmark-off-size);
        opacity: 0.5;
        background-color: transparent;
        border: $radio-border solid neutral.$white_100;
      }
    }

    #{$base}__input {
      /* Checked effect */
      &:checked + #{$base}__checkmark {
        &:before {
          -webkit-box-shadow: effects.$drop-shadow-down-xs-dark;
                  box-shadow: effects.$drop-shadow-down-xs-dark;
        }
        &:after {
          @include mixins.size($radio-checkmark-on-size);
          background: neutral.$white_100;
        }
      }
    }

    #{$base}__busy {
      @include mixins.size($radio-size);
    }
  }

  /* --- Switch --- */
  &--switch {
    /* Faux switch component */
    &:before,
    #{$base}__checkmark {
      @include mixins.size($switch-width, $switch-height);
      border-radius: spacing.$spacing-s;
    }

    #{$base}__checkmark {
      /* Middle circle (slider) */
      &:before,
      &:after {
        left: $switch-checkmark-space;
        @include mixins.size($switch-checkmark-size);
        @include mixins.transition(left, $animation-duration);
        opacity: 1;
      }
    }

    #{$base}__input {
      /* Checked effect */
      &:checked + #{$base}__checkmark {
        &:before,
        &:after {
          left: (
            $switch-width - $switch-checkmark-space - $switch-checkmark-size
          );
        }
      }
    }

    #{$base}__busy {
      @include mixins.size($switch-width, $switch-height);
    }
  }

  &__busy {
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    @include mixins.size($input-size);
  }

  &--busy {
    pointer-events: none;
    cursor: default;

    &:before,
    #{$base}__input,
    #{$base}__checkmark {
      display: none;
    }
  }

  &__busy-dot {
    position: absolute;
    -webkit-animation: ckw 0.5s linear infinite;
            animation: ckw 0.5s linear infinite;
  }

  @-webkit-keyframes ckw {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes ckw {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
}

/* --- Radio Button Group --- */
.toggle-group {
  .control {
    margin: spacing.$spacing-s 0;
  }
}


/* --- With Icon --- */
.icon-toggle {
  $base: &;

  &--radio {
    @include mixins.button-color-interaction(neutral.$neutral_03);

    input[checked] + #{$base}__icon {
      @include mixins.svg-fill(accent.$accent_05);
    }

    input[disabled] + #{$base}__icon {
      @include mixins.svg-fill(neutral.$neutral_02);
    }
  }

  /* Hide the browser's default input to use custom */
  &__input {
    @include hideInput;
  }
}