@use "../../utilities/mixins";

.btn-options {
  &.as-icon {
    @include mixins.size(32px);
    padding: 0;
    /*scaling down to 24px since we do not have asset*/
    -webkit-transform: scale(0.75);
            transform: scale(0.75);

    .icon,
    .icon svg {
      width: 32px !important;
      height: 32px !important;
    }
  }
}
